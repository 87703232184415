import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { getAllParams } from '/src/functions/paramTools'

const MobileLinksPage = loadable(() => import('/src/templates/MobileLinksPage'))
const PageBuilderPage = loadable(() => import('/src/templates/PageBuilderPage'))
const SidebarPage = loadable(() => import('/src/templates/SidebarPage'))
const StandardPage = loadable(() => import('/src/templates/StandardPage'))

const PageTemplate = ({ data: { post } }) => {
    const pageTemplate = post.template?.templateName

    const [pageUtms, setPageUtms] = useState('')

    useEffect(() => {
        const addedUtms = post.acfPage?.utms || []
        setPageUtms(getAllParams(addedUtms))
    }, [post])

    if (pageTemplate === 'Page Builder') {
        return <PageBuilderPage post={post} utm={pageUtms} />
    } else if (pageTemplate === 'Mobile Links') {
        return <MobileLinksPage post={post} />
    } else if (pageTemplate === 'Form With Sidebar' || pageTemplate === 'Content With Sidebar') {
        return <SidebarPage post={post} />
    } else {
        return <StandardPage post={post} />
    }
}

PageTemplate.propTypes = {
    /**
     * Post object
     */
    data: PropTypes.object.isRequired
}

export default PageTemplate

export const pageQuery = graphql`
    query PageById(
        # these variables are passed in via createPage.pageContext in gatsby-node.js
        $id: String!
    ) {
        # selecting the current post by id
        post: wpPage(id: { eq: $id }) {
            id
            slug
            title
            isFrontPage
            template {
                templateName
            }

            ...SeoForPage

            wpParent {
                node {
                    ... on WpPage {
                        title
                        uri
                        wpParent {
                            node {
                                ... on WpPage {
                                    title
                                    uri
                                }
                            }
                        }
                    }
                }
            }

            acfPage {
                removeHeader
                removeMainNavigation
                removeCta
                useAlternativeBranding
                branding
                utms {
                    addUtms
                    utmsList {
                        key
                        value
                    }
                }
                addExternalScript
                scriptSourceUrl
            }

            acfPageContent {
                content
            }

            ...PageHeader

            ...PageBuilderForPage

            ...MobileLinks

            ...FormPage
        }
    }
`
